import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-encourager',
  templateUrl: './encourager.component.html',
  styleUrls: ['./encourager.component.scss'],
})
export class EncouragerComponent implements OnInit {

  @Input() title: any;
  @Input() buttonActive: boolean;


  constructor(private navigationService:NavigationService) { }

  ngOnInit() {}


  openEnquiry(){

    this.navigationService.selectedPage.next('enquire');
  }

}
