import { Component } from '@angular/core';
import { NavigationService } from './services/navigation.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(private navigationService:NavigationService) {}


  nav(page:string){
    this.navigationService.selectedPage.next(page);
    this.closeMenu();
  }

closeMenu(){}
 
}
