import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-client-slide',
  templateUrl: './client-slide.component.html',
  styleUrls: ['./client-slide.component.scss'],
})
export class ClientSlideComponent implements OnInit {


  slideOpt = {
    loop: true,
    slidesPerView:2,
    spaceBetween: 30

  };

  imageCount :any;


  constructor() { }

  ngOnInit() {

    const N = 55;
    this.imageCount = [...Array(N).keys()].map(x => ++x);

    console.log(this.imageCount);


  }



}
