import { ClientSlideComponent } from './client-slide/client-slide.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientComponent } from './client/client.component';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { CongratulationsComponent } from './congratulations/congratulations.component';
import { EncouragerComponent } from './encourager/encourager.component';
import { EnquirePageComponent } from './enquire-page/enquire-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MissionStatementComponent } from './mission-statement/mission-statement.component';
import { ConsultationOptionsComponent } from './consultation-options/consultation-options.component';



@NgModule({
  declarations: [
  
    ClientComponent,
    ClientDetailComponent,
    ClientSlideComponent,
    CongratulationsComponent,
    EncouragerComponent,
    EnquirePageComponent,
    HomePageComponent,
    MissionStatementComponent,
    ConsultationOptionsComponent

  ],
  imports: [
      CommonModule,IonicModule,FormsModule,ReactiveFormsModule
  ],
  exports: [
    
    ClientComponent,
    ClientDetailComponent,
    ClientSlideComponent,
    CongratulationsComponent,
    EncouragerComponent,
    EnquirePageComponent,
    HomePageComponent,
    MissionStatementComponent,
    ConsultationOptionsComponent



  ],
  providers: []
})

export class ComponentsModule {

}
