import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CongratulationsComponent } from '../congratulations/congratulations.component';
import { IonContent, IonSlides, ModalController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ConsultationOptionsComponent } from '../consultation-options/consultation-options.component';
@Component({
  selector: 'app-enquire-page',
  templateUrl: './enquire-page.component.html',
  styleUrls: ['./enquire-page.component.scss'],
})
export class EnquirePageComponent implements OnInit {
  @ViewChild('mySlider') slides: IonSlides;

  submittedForm = false;

  clientForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
    ]),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    contactNumber: new FormControl('', Validators.required),
  });

  clientCount: number;
  weekCount: number;
  quote: number;
  programmeDetailsPresent: boolean;

  sessionsCount: number;

  trainingType: string;

  personal: boolean;
  online: boolean;
  onlineBootcamp: boolean;
  morningBootcamp: boolean;
  eveningBootcamp: boolean;

  coach: any;

  slideIndex: number;

  slideOpts: any;

  consultationFloaterOpen: boolean;

  constructor(
    private http: HttpClient,
    private modalController: ModalController
  ) {}

  ngOnInit() {

    this.consultationFloaterOpen = true;

    this.slideIndex = 0;
    this.sessionsCount = 4;

    this.slideOpts = {
      spaceBetween: 300,
    };
  }

  async openConsultationModal(){
    const modal = await this.modalController.create({
      component: ConsultationOptionsComponent,
      backdropDismiss: true,
      cssClass: 'my-custom-modal-css',
    });

    modal.present();
  }

  closeConsultationFloater(){

    this.consultationFloaterOpen = false;
  }

  restart() {
    this.reset();
    this.slides.slideTo(0, 250);
    this.slideIndex = 0;
  }

  startWizard() {
    this.nextSlide();
  }

  nextSlide() {
    setTimeout(() => this.slides.slideNext(), 250);
    if (!this.weekCount) {
      this.weekCount = 4;
    }
    this.calculateQuote();
    this.slideIndex++;
  }

  setIsPersonalTraining() {
    this.personal = true;
    this.online = false;
    this.onlineBootcamp = false;
    this.morningBootcamp = false;
    this.eveningBootcamp = false;

    this.trainingType = 'personal';
    this.nextSlide();
  }

  setIsOnlineTraining() {
    this.personal = false;
    this.online = true;
    this.onlineBootcamp = false;
    this.morningBootcamp = false;
    this.eveningBootcamp = false;

    this.trainingType = 'online';
    this.clientCount = 1;
    this.nextSlide();
  }

  setIsOnlineBootcamp() {
    this.personal = false;
    this.online = false;
    this.onlineBootcamp = true;
    this.morningBootcamp = false;
    this.eveningBootcamp = false;

    this.trainingType = 'online bootcamp';
    this.clientCount = 1;
    this.weekCount = 4;
    this.sessionsCount = 3;
    this.quote = 40;
    this.nextSlide();
  }

  setIsMorningBootcamp() {
    this.personal = false;
    this.online = false;
    this.onlineBootcamp = false;
    this.morningBootcamp = true;
    this.eveningBootcamp = false;

    this.trainingType = 'morning bootcamp';
    this.clientCount = 1;
    this.weekCount = 4;
    this.sessionsCount = 2;
    this.quote = 65;
    this.nextSlide();
  }

  setIsEveningBootcamp() {
    this.personal = false;
    this.online = false;
    this.onlineBootcamp = false;
    this.morningBootcamp = false;
    this.eveningBootcamp = true;

    this.trainingType = 'evening bootcamp';
    this.clientCount = 1;
    this.weekCount = 4;
    this.sessionsCount = 3;
    this.quote = 99;
    this.nextSlide();
  }

  setClientCount(count: number) {
    this.clientCount = count;
    this.calculateQuote();
    this.nextSlide();
  }

  setWeekCount(count: number) {
    this.weekCount = count;
    this.calculateQuote();
  }

  setSessionCount(count: number) {
    this.sessionsCount = count;
    this.calculateQuote();
  }

  reset() {
    this.clientCount = null;
    this.weekCount = 4;
    this.quote = null;
    this.programmeDetailsPresent = false;
    this.trainingType = null;
    this.personal = false;
    this.online = false;
    this.onlineBootcamp = false;
    this.morningBootcamp = false;
    this.eveningBootcamp = false;
    this.coach = '';

    this.sessionsCount = 4;
  }

  setCoach(gender: any) {
    this.coach = gender;
    this.nextSlide();
  }

  calculatePersonalTrainingQuote(
    clients: number,
    weeks: number,
    sessions: number
  ) {
    let price = 0;

    if (clients === 1) {
      if (weeks === 4) {
        if (sessions === 1) {
          price = 100;
        }
       else if (sessions === 2) {
          price = 180;
        } else if (sessions === 3) {
          price = 240;
        } else {
          price = 288;
        }
      }

      if (weeks === 6) {
        if (sessions === 1) {
          price = 150;
        }
       else if (sessions === 2) {
          price = 270;
        } else if (sessions === 3) {
          price = 360;
        } else {
          price = 432;
        }
      }

      if (weeks === 8) {
        if (sessions === 1) {
          price = 200;
        }
       else if (sessions === 2) {
          price = 360;
        } else if (sessions === 3) {
          price = 480;
        } else {
          price = 576;
        }
      }

      if (weeks === 10) {
        if (sessions === 1) {
          price = 250;
        }
       else if (sessions === 2) {
          price = 450;
        } else if (sessions === 3) {
          price = 600;
        } else {
          price = 720;
        }
      }

      if (weeks === 12) {
        if (sessions === 1) {
          price = 300;
        }
       else  if (sessions === 2) {
          price = 540;
        } else if (sessions === 3) {
          price = 720;
        } else {
          price = 864;
        }
      }
    } else {
      if (weeks === 4) {
        if (sessions === 2) {
          price = 154;
        } else if (sessions === 3) {
          price = 229;
        } else {
          price = 299;
        }
      }

      if (weeks === 6) {
        if (sessions === 2) {
          price = 224;
        } else if (sessions === 3) {
          price = 329;
        } else {
          price = 429;
        }
      }

      if (weeks === 8) {
        if (sessions === 2) {
          price = 249;
        } else if (sessions === 3) {
          price = 379;
        } else {
          price = 460;
        }
      }

      if (weeks === 10) {
        if (sessions === 2) {
          price = 399;
        } else if (sessions === 3) {
          price = 499;
        } else {
          price = 599;
        }
      }

      if (weeks === 12) {
        if (sessions === 2) {
          price = 375;
        } else if (sessions === 3) {
          price = 548;
        } else {
          price = 700;
        }
      }
    }

    return price;
  }

  calculateQuote() {
    if (
      this.clientCount &&
      this.weekCount &&
      (this.online || this.personal || this.onlineBootcamp)
    ) {
      this.programmeDetailsPresent = true;
    } else {
      this.programmeDetailsPresent = false;
    }

    let people = this.clientCount;
    let weeks = this.weekCount;
    let sessions = this.sessionsCount;

    if (this.personal) {
      this.quote = this.calculatePersonalTrainingQuote(people, weeks, sessions);
    } else if (this.online) {
      switch (weeks) {
        case 4: {
          this.quote = 80;
          break;
        }
        case 6: {
          this.quote = 120;

          break;
        }
        case 8: {
          this.quote = 160;
          break;
        }

        case 10: {
          this.quote = 200;
          break;
        }

        case 12: {
          this.quote = 240;
          break;
        }
        default: {
          this.quote = 0;
          break;
        }
      }
    } else {
      this.quote = 40;
    }
  }

  enquire() {
    // event.preventDefault();
    this.submittedForm = true;

    let data = {
      service_id: 'service_mfuhqyu',
      template_id: 'template_q84rhrk',
      user_id: 'user_f76S2NsvaNKt0ulysow5U',
      template_params: {
        fname: this.clientForm.controls.firstName.value,
        lname: this.clientForm.controls.lastName.value,
        email: this.clientForm.controls.email.value,
        contactNumber: this.clientForm.controls.contactNumber.value,
        clientCount: this.clientCount,
        trainingType: this.trainingType,
        weeks: this.weekCount,
        quote: this.quote,
        sessions: this.sessionsCount,
        coach: this.coach,
      },
    };

    this.http
      .post('https://api.emailjs.com/api/v1.0/email/send', data, {
        responseType: 'text',
      })
      .subscribe(
        (result) => {},
        (error: HttpErrorResponse) => {}
      );
  }

  async openCongrats() {
    this.enquire();

    let completeClient = {
      fname: this.clientForm.controls.firstName.value,
      lname: this.clientForm.controls.lastName.value,
      email: this.clientForm.controls.email.value,
      contactNumber: this.clientForm.controls.contactNumber.value,
      clientCount: this.clientCount,
      weeks: this.weekCount,
      quote: this.quote,
      trainingType: this.trainingType,
      sessions: this.sessionsCount,
      coach: this.coach,
    };

    const modal = await this.modalController.create({
      component: CongratulationsComponent,
      componentProps: { client: completeClient },
      backdropDismiss: true,
      cssClass: 'my-custom-modal-css',
    });

    modal.present();
  }
}
