import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-consultation-options',
  templateUrl: './consultation-options.component.html',
  styleUrls: ['./consultation-options.component.scss'],
})
export class ConsultationOptionsComponent implements OnInit {
  @ViewChild('mySlider') slides: IonSlides;

  inPerson: boolean;
  zoom: boolean;
  call: boolean;
  slideOpts: any;



  constructor(private modalController:ModalController) { }


  ngOnInit() {
    this.slideOpts = {
      spaceBetween: 300,
    };

  }

  nextSlide() {
    setTimeout(() => this.slides.slideNext(), 250);
  }

  setIsInPerson(){
    this.inPerson = true;

    this.nextSlide();

  }

  setIsZoom(){
    this.zoom = true;

    this.nextSlide();

  }

  setIsCall(){
    this.call = true;

    this.nextSlide();

  }
  closeModal() {
    this.modalController.dismiss();
 }

}
