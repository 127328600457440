import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss'],
})
export class ClientDetailComponent implements OnInit {

  @Input() client: any;
  testimonialVisible: boolean;

  slideOpt = {
    loop: true,
    autoplay:true,
  };


  constructor(private modalController: ModalController, private navigationService: NavigationService) { }

  ngOnInit() {
    this.testimonialVisible = true;
  }



  closeModal() {
    this.modalController.dismiss();
  }

  openEnquiry() {
    this.modalController.dismiss();
    this.navigationService.selectedPage.next('enquire');
  }

  toggleTestimonial() {
    this.testimonialVisible = !this.testimonialVisible;

  }
}


