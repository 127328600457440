import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss'],
})
export class CongratulationsComponent implements OnInit {

  @Input() client:any;
  constructor(private modalController:ModalController, private navigationService:NavigationService) { }

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
 }


 openHome(){
  this.modalController.dismiss();
  this.navigationService.selectedPage.next('home');
}


}
