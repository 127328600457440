import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})


export class NavigationService {
  selectedPage: any;



  constructor() { 
    this.selectedPage = new BehaviorSubject(this.selectedPage); 

  }
}
